

import Storage from "@/util/Storage";
import { delBarList, getBarList } from "@/Api/ShopAdmin";
import { Toast } from "vant";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let userInfo: any = {}
// 商品分类 列表
export function getProductBarList(body: any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    userInfo = Storage.GetData_!("UserData")
    try {
        try {
            userInfo = JSON.parse(userInfo as string)
        } catch (e) {
            userInfo = eval(userInfo as string)
        }
    } catch (e) {
        console.info("转换失败")
    }

    body.schoolCode = userInfo.school
    return Promise.resolve(getBarList({ userId, token }, body).then(res => {
        return res.data
    }))
}

// 删除商品分类
export function delProductBar(id: string | number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    userInfo = Storage.GetData_!("UserData")
    try {
        try {
            userInfo = JSON.parse(userInfo as string)
        } catch (e) {
            userInfo = eval(userInfo as string)
        }
    } catch (e) {
        console.info("转换失败")
    }

    Toast.loading({ duration: 0, message: "删除中~~~" })
    return Promise.resolve(delBarList({ userId, token }, { id, schoolCode: userInfo.school || '' }).then(res => {
        Toast.clear()
        return res.data
    }))
}
