import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import ProductBarStoreType from "@/views/SocialCircle/ProductBar/Store/indexType";

@Module({
    name:"ProductBar",
    store,
    dynamic: true,
})
export default class ProductBar extends VuexModule implements ProductBarStoreType{
    SelectProductBar: any = {};
    get getSelectProductBar(){
        return this.SelectProductBar
    }
    @Mutation
    SetSelectProductBar(data: any): void {
        this.SelectProductBar = data
    }

}

export const ProductBarStore = getModule( ProductBar )
