import Axios from "../Config/shop"
import {
    addLimitProductType,
    addressOrderNumType,
    bikeDisPathSucType,
    delLimitProductType,
    getLimitProductType,
    HeaderType,
    updateLimitProductType,
    getBarListType,
    updateBarListType,
    addBarListType,
    getSpecialProductType,
    setSpecialProductType,
    delSpecialProductType,
    BodyType,
    addLimitTimeDateType,
    updateLimitTimeDateType, getLimitTimeDateType, updateAreaListType, addAreaListType, delAreaListType, getAreaListType
} from "@/Api/ShopAdmin/interfaceType";


// 配送信息
export function disPathSuc( headers:HeaderType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/sendInfo",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 骑手信息 body:bikeDisPathSucType
export function bikeDisPathSuc( headers:HeaderType,body = {} ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayOrderSendInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 商户信息
export function tenantSuc( headers:HeaderType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayAreaSendOrder",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 地址订单量
export function addressOrderNum( headers:HeaderType,body:addressOrderNumType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayAreaOrderInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 地址时长
export function addressOrderTime( headers:HeaderType,body:addressOrderNumType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayStoreOrderTimeInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}


/**                限时购           */
// 添加限时购
export function addLimitProduct( headers:HeaderType,body:addLimitProductType ):Promise<any> {
    return (
        Axios({
            url:"/app/time_limit/addProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改
export function updateLimitProduct( headers:HeaderType,body:updateLimitProductType ):Promise<any> {
    return (
        Axios({
            url:"/app/time_limit/updateProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看
export function getLimitProduct( headers:HeaderType,body:getLimitProductType ):Promise<any> {
    return (
        Axios({
            url:"/app/time_limit/searchProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除
export function delLimitProduct( headers:HeaderType,body:delLimitProductType ):Promise<any> {
    return (
        Axios({
            url:"/app/time_limit/deleteProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}


                /**        限时购时间         */

// 查询
export function getLimitTimeDate( headers:HeaderType,body:getLimitTimeDateType ):Promise<any> {
    return (
        Axios({
            url:"/app/product/time/queryTimeList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除
export function delLimitTimeDate( headers:HeaderType,body:delLimitProductType ):Promise<any> {
    return (
        Axios({
            url:"/app/product/time/deleteTime",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加
export function addLimitTimeDate( headers:HeaderType,body:addLimitTimeDateType ):Promise<any> {
    return (
        Axios({
            url:"/app/product/time/createTime",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 秀爱
export function updateLimitTimeDate( headers:HeaderType,body:updateLimitTimeDateType ):Promise<any> {
    return (
        Axios({
            url:"/app/product/time/updateTime",
            method:"POST",
            headers,
            data:body
        })
    )
}

//  社圈  管理端

// 导航栏列表
export function getBarList ( headers : HeaderType , body:getBarListType ):Promise<any>{
    return (
        Axios({
            url:"/app/classify/classifyList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除导航栏
export function delBarList ( headers : HeaderType , body:delLimitProductType ):Promise<any>{
    return (
        Axios({
            url:"/app/classify/deleteClassify",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加导航栏
export function addBarList ( headers : HeaderType , body:addBarListType ):Promise<any>{
    return (
        Axios({
            url:"/app/classify/createClassify",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改导航栏
export function updateBarList ( headers : HeaderType , body:updateBarListType ):Promise<any>{
    return (
        Axios({
            url:"/app/classify/updateClassify",
            method:"POST",
            headers,
            data:body
        })
    )
}



            /**       特价商品      */
// 获取特价商品
export function getSpecialProduct ( headers : HeaderType , body:getSpecialProductType ):Promise<any>{
    return (
        Axios({
            url:"/app/community/getOffersProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 设置特价商品
export function setSpecialProduct ( headers : HeaderType , body:setSpecialProductType ):Promise<any>{
    return (
        Axios({
            url:"/app/community/setOffersProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除特价商品
export function delSpecialProduct ( headers : HeaderType , body:delSpecialProductType ):Promise<any>{
    return (
        Axios({
            url:"/app/community/deleteOffersProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}


/**                             店铺 骑手 选择多个区域 售卖或配送 */

// 查询 店铺 骑手 多个区域
export function getAreaList ( headers : HeaderType , body:getAreaListType ):Promise<any>{
    return (
        Axios({
            url:"/app/delivery/area/queryList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除店铺 骑手 多个区域
export function delAreaList ( headers : HeaderType , body:delAreaListType ):Promise<any>{
    return (
        Axios({
            url:"/app/delivery/area/delete",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加店铺 骑手 多个区域
export function addAreaList ( headers : HeaderType , body:addAreaListType ):Promise<any>{
    return (
        Axios({
            url:"/app/delivery/area/create",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改店铺 骑手 多个区域
export function updateAreaList ( headers : HeaderType , body:updateAreaListType ):Promise<any>{
    return (
        Axios({
            url:"/app/delivery/area/modify",
            method:"POST",
            headers,
            data:body
        })
    )
}
